import React from 'react'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <h1>Argh we don&#39;t seem to have that page</h1>
    <p>... the sadness.</p>
  </Layout>
)

export default NotFoundPage
